import React, { useState, useRef } from 'react';

import { useMediaQuery } from '@mui/material';
import ContactFormButton from './contactFormButton';
import Box from '@mui/material/Box';
import ContactFormEmailInput from './contactFormEmailInput';
import ContactFormNameInput from './contactFormNameInput';
import ContactFormPhoneInput from './contactFormPhoneInput';

const ContactFormInputPane = (props) => {
  console.log('ContactFormInputPage props: ', props);
  const [currentName, setCurrentName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [currentPhone, setCurrentPhone] = useState(null);
  const [isValid, setIsValid] = useState(false);


  const handleNameChange = (name) => {
    console.log('contactFormInputPane.handleNameChange() name: ', name);
    setCurrentName(name);
  };

  const handleEmailChange = (email) => {
    console.log('contactFormInputPane.handleEmailChange() email: ', email);
    setCurrentEmail(email);
  };

  const handlePhoneChange = (phone) => {
    console.log('contactFormInputPane.handleEmailChange() phone: ', phone);
    setCurrentPhone(phone);
  };

  const onSubmitClickHandler = (event) => {
    props.onSubmit({name: currentName, email: currentEmail, phone: currentPhone });
  };

  let inputPadding = 16;
  if (useMediaQuery('(min-width:1200px)')) inputPadding = 50;

  return (
    <Box
      id="contact-form-input-pane"
      sx={{
        position: 'relative',
        paddingLeft: { xs: '16px', lg: '50px'},
        paddingRight: { xs: '16px', lg: '50px'},
        // backgroundColor: '#F4615F',
        height: '100%'
      }}
    >

      <ContactFormNameInput padding={inputPadding} onChange={handleNameChange} />
      <ContactFormEmailInput padding={inputPadding} onChange={handleEmailChange} />
      <ContactFormPhoneInput padding={inputPadding} onChange={handlePhoneChange} />

      <Box 
        sx={{
          position: 'relative'
        }}
      >
        <ContactFormButton text="Submit" onClick={onSubmitClickHandler} disabled={!isValid} />
      </Box>
    </Box>
  )
}

export default ContactFormInputPane;