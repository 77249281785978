import React, { useState, useRef } from 'react';

import Box from '@mui/material/Box';

const ContactFormPhoneInput = (props) => {
  const { padding = 16 } = props;
  
  const [currentPhone, setCurrentPhone] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [timerHandle, setTimerHandle] = useState(null);

  const phoneInput = useRef();

  const validateCurrentPhone = (phone) => {
    if (typeof phone !== 'string' && !(phone instanceof String)) {
      setIsValid(false);
      return;
    }
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const matches = phone.match(regex);
    console.log('contactFormPhoneInput.validateCurrentPhone() matches: ', matches);
    if (matches && matches[0]) {
      setIsValid(true);
      setIsDirty(false);
    } else {
      setIsValid(false);
    }
  };

  const startTimer = () => {
    const handle = setTimeout(() => {
      setIsDirty(true);
    }, 1000);
    setTimerHandle(handle);
  };

  const onChangeHandler = (event) => {
    if (timerHandle) clearTimeout(timerHandle);
    // console.log('contactFormPhoneInput.onChangeHandler() event.target.value: ', event.target.value);
    props.onChange(event.target.value);
    setCurrentPhone(event.target.value);
    validateCurrentPhone(event.target.value);
    startTimer();
  };

  const errorMessage = (currentPhone && isDirty && !isValid) ? "Please enter a valid phone number." : "";

  return (
    <Box 
      sx={{
        position: 'relative',
        marginBottom: '19px',
      }}
    >
      <input 
        type="email" 
        ref={phoneInput}
        placeholder="Phone number" 
        onChange={onChangeHandler}
        style={{
          width: `calc(100% - ${padding * 2}px)`,
          height: '65px',
          borderRadius: '20px',
          background: '#FFFFFF',
          fontSize: '24px',
          border: 'none',
          boxSizing: 'border-box',
          paddingLeft: '20px',
          outline: 'none',
          color: '#787879',
          border: '1px solid #999999',       
        }}
      />

      <Box
        sx={{
          position: 'relative',
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          color: '#ffffff',
          fontSize: '0.875rem',
          minHeight: '1.2rem',
        }}
      >
        {errorMessage}
      </Box>

    </Box>
  )
}

export default ContactFormPhoneInput;