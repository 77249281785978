import React, { useState, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import callApi from '../../services/apiService';

import Box from '@mui/material/Box';
import ContactFormInputPane from './contactFormInputPane';
import ContactFormConfirmationPane from './contactFormConfirmationPane';
import { Typography } from '@mui/material';

const ContactForm = (props) => {
  const {
    backgroundColor = '#F4615F',
    showTopLine,          // controls whether or not the top line of text is shown
  } = props;    
  const [showSpinner, setShowSpinner] = useState(false);


  const handleSubmitButtonClick = useCallback((data) => {
    console.log('contactForm.js.handleSubmitButtonClick() data: ', data);
    setShowSpinner(true);
    callApi('createSalesInquiry', null, data, (err, apiResponse) => {
      console.log('contactForm.handleSubmitButtonClick() createEmailSignup; err: ', err);
      console.log('contactForm.handleSubmitButtonClick() createEmailSignup; apiResopnse: ', apiResponse);
      let status = null;
      if (err) {
        status = 'error';
      } else if (apiResponse && apiResponse.data && apiResponse.data.status) {
        status = apiResponse.data.status;
      } else if (apiResponse && apiResponse.statusText && (apiResponse.statusText !== 'OK')) {
        status = 'error';
      }
      setShowSpinner(false);
      console.log('contactForm.handleSubmitButtonClick() about to set confirmationItem with status: ', status);
      setItems(confirmationItemFactory(status));
    });
  }, []);


  /**
   * Note that classNames="item" actually resolves to .item-enter, .item-enter-active, .item-exit, .item-exit-active, etc.
   */
  const formItemFactory = () => {
    return (
      // <CSSTransition key={0} timeout={500} in={true} classNames="item" unmountOnExit>
        <Box
          sx={{
            height: '500px'
          }}
        >
          <ContactFormInputPane onSubmit={handleSubmitButtonClick} />
        </Box>
      // </CSSTransition>
    );
  };

  const confirmationItemFactory = (status) => {
    return (
      // <CSSTransition key={1} timeout={500} in={true} classNames="item" unmountOnExit>
        <Box
          sx={{
            height: '500px'
          }}
        >
          <ContactFormConfirmationPane status={status} />
        </Box>
      // </CSSTransition>
    );
  };

  const [items, setItems] = useState(formItemFactory());

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: backgroundColor,
        margin: 0,
        height: '100%',
        paddingTop: { xs: '30px', lg: '70px' }
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: { xs: '22px', lg: '26px' },
          lineHeight: { xs: '36px', lg: '44px' },
          textAlign: 'center',
          color: '#FFFFFF',
          display: showTopLine ? 'block' : 'none'
        }}
      >
        Bring Bendable to your library.
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: { xs: '22px', lg: '26px' },
          lineHeight: { xs: '36px', lg: '44px' },
          textAlign: 'center',
          color: '#FFFFFF',
          marginBottom: { xs: '2rem', lg: '2.5rem' }
        }}
      >
        Schedule a conversation.
      </Typography>

      {/* <TransitionGroup>   // This fails by showing both the new and old state, so let's remove it
        {items}
      </TransitionGroup> */}

      {items}

    </Box>
  )
};

export default ContactForm;